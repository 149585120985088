import React, { useState, useEffect, useRef } from "react";
import LayoutFillImg from "../../../Misc/LayoutFillImg/LayoutFillImg";
import { RiDeleteBinLine } from "react-icons/ri";
import { PiSlidersBold } from "react-icons/pi";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import Section from "../../../Misc/section/Section";
import Container from "../../../Misc/container/Container";
import Text from "../../../Misc/text/Text";

import styles from "./HomePageEditor.module.scss";


const HomePageEditor = () => {
  const [selectedService, setSelectedService] = useState("Smooth Pavers");
  const [selectedImmediately, setSelectedImmediately] = useState("Modern");
  const [selectedLocation, setSelectedLocation] = useState("Front Porch");
  const [isServiceOpen, setIsServiceOpen] = useState(false);
  const [isImmediatelyOpen, setIsImmediatelyOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);

  const serviceRef = useRef(null);
  const immediatelyRef = useRef(null);
  const locationRef = useRef(null);

  const closeDropdowns = () => {
    setIsServiceOpen(false);
    setIsImmediatelyOpen(false);
    setIsLocationOpen(false);
  };

  const handleDocumentClick = (event) => {
    if (
      serviceRef.current &&
      !serviceRef.current.contains(event.target) &&
      immediatelyRef.current &&
      !immediatelyRef.current.contains(event.target) &&
      locationRef.current &&
      !locationRef.current.contains(event.target)
    ) {
      closeDropdowns();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleServiceClick = () => {
    closeDropdowns();
    setIsServiceOpen(!isServiceOpen);
  };

  const handleImmediatelyClick = () => {
    closeDropdowns();
    setIsImmediatelyOpen(!isImmediatelyOpen);
  };
 
  const handleLocationClick = () => {
    closeDropdowns();
    setIsLocationOpen(!isLocationOpen);
  };

  const handleServiceItemClick = (item) => {
    setSelectedService(item);
    setIsServiceOpen(false);
  };

  const handleImmediatelyItemClick = (item) => {
    setSelectedImmediately(item);
    setIsImmediatelyOpen(false);
  };

  const handleLocationItemClick = (item) => {
    setSelectedLocation(item);
    setIsLocationOpen(false);
  };

  return (
    <Section className={styles.formmainContainer}>
      <Container>
        <div className={styles.headerstyle}>
          <br />
          <h2 className={styles.sectionHeading}>
            Custom Project Planning & Design Editor
          </h2>
          <h3 className={styles.sectionSubheading}>
            See What You Can Achieve in Minutes
          </h3>
          <Text>
            Plan your home redesign, build a quote and associate your quote with
            your designs
          </Text>
          <Text>
            all in an AI assisted editor which takes away the complication, need
            for skill or any ambiguity
          </Text>
          <br />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.form_1x}>
            <div className={styles.form_x1}>
              <div className={styles.formuploadheading}>
                <h2 className={styles.formheading}>Upload photo</h2>
                <div className={styles.questionIcon}>
                  <LayoutFillImg
                    src="/assets/questionmarkIcon.svg"
                    alt="Question mark Icon"
                  />
                </div>
              </div>

              {/* UPlaod Files input  */}
              <div className={styles.formuploadfile}>
                <input
                  type="file"
                  id="file"
                  className="hidden"
                  style={{ display: "none" }}
                />
                <label htmlFor="file" className={styles.formlabel}>
                  <div className={styles.formuploadICon}>
                    <LayoutFillImg src="/assets/upload.svg" alt="upload" />
                  </div>
                </label>
              </div>

              {/* Delete & Edit Btns  */}
              <div className={styles.formdeleteeditBtn}>
                <button className={styles.formdeleteBtn}>
                  <RiDeleteBinLine className={styles.formdeleteIcon} />
                  delete
                </button>
                <button className={styles.formdeleteBtn}>
                  <PiSlidersBold className={styles.formdeleteIcon} />
                  Edit
                </button>
              </div>

              <button className={styles.formgenerBtn}>Regenerate</button>
            </div>

            <div className={styles.form_x2}>
              <div className={styles.formtheme_xx1}>
                <div className={styles.formtheme}>
                  <p>Theme</p>
                  <IoIosArrowUp className={styles.formupArrow} />
                </div>
                <div
                  ref={immediatelyRef}
                  className={styles.formmoderndrop}
                  onClick={handleImmediatelyClick}
                >
                  <div className={styles.formDropdown_x1}>
                    <p>{selectedImmediately}</p>
                    <IoIosArrowDown />
                  </div>
                  {isImmediatelyOpen && (
                    <ul className={styles.formsmothdrop}>
                      <li
                        onClick={() => handleImmediatelyItemClick("Modern 1")}
                      >
                        Modern 1
                      </li>
                      <li onClick={() => handleServiceItemClick("Modern 2")}>
                        Modern 2
                      </li>
                      <li onClick={() => handleServiceItemClick("Modern 3")}>
                        Modern 3
                      </li>
                    </ul>
                  )}
                </div>
              </div>

              <div className={styles.formtheme_xx2}>
                <div className={styles.formtheme}>
                  <p>Pavers</p>
                  <IoIosArrowUp className={styles.formupArrow} />
                </div>

                <div style={{ position: "relative" }} ref={serviceRef}>
                  <div
                    className={styles.formDropdown_x1}
                    onClick={handleServiceClick}
                  >
                    <p>{selectedService}</p>
                    <IoIosArrowDown />
                  </div>
                  {isServiceOpen && (
                    <ul className={styles.formsmothdrop}>
                      <li
                        onClick={() =>
                          handleServiceItemClick("Smooth Pavers 1")
                        }
                      >
                        Smooth Pavers 1
                      </li>
                      <li
                        onClick={() =>
                          handleServiceItemClick("Smooth Pavers 2")
                        }
                      >
                        Smooth Pavers 2
                      </li>
                      <li
                        onClick={() =>
                          handleServiceItemClick("Smooth Pavers 3")
                        }
                      >
                        Smooth Pavers 3
                      </li>
                    </ul>
                  )}
                </div>

                <div className={styles.formsqft}>
                  <p>Sqft</p>
                  <span>01</span>
                </div>

                <div className={styles.formporch}>
                  <div style={{ position: "relative" }} ref={locationRef}>
                    <div
                      className={styles.formpodrop}
                      onClick={handleLocationClick}
                    >
                      <p>{selectedLocation}</p>
                      <IoIosArrowDown />
                    </div>
                    {isLocationOpen && (
                      <ul className={styles.formsmothdrop}>
                        <li
                          onClick={() =>
                            handleLocationItemClick("Front Porch 1")
                          }
                        >
                          Front Porch 1
                        </li>
                        <li
                          onClick={() =>
                            handleLocationItemClick("Front Porch 2")
                          }
                        >
                          Front Porch 2
                        </li>
                        <li
                          onClick={() =>
                            handleLocationItemClick("Front Porch 3")
                          }
                        >
                          Front Porch 3
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className={styles.formpornumber}>$2,46677-4,6667</div>
                </div>

                <div className={styles.formtext}>
                  Select the part of your home that you want to redesign
                </div>

                <div className={styles.formuploadfile}>
                  <input
                    type="file"
                    id="file"
                    className="hidden"
                    style={{ display: "none" }}
                  />
                  <label htmlFor="file" className={styles.formlabel}>
                    <p>Select Image</p>
                    <div className={styles.formuploadICon}>
                      <FaPlus />
                    </div>
                  </label>
                </div>
              </div>
            </div>

            <div className={styles.form_x3}>
              <button className={styles.formgenerBtn}>Regenerate Design</button>

              <ul className={styles.formlists}>
                <li className={styles.formlist}>
                  Hardscaping <IoIosArrowDown className={styles.formlistIcon} />
                </li>
                <li className={styles.formlist}>
                  Hardscaping <IoIosArrowDown className={styles.formlistIcon} />
                </li>
                <li className={styles.formlist}>
                  Water features{" "}
                  <IoIosArrowDown className={styles.formlistIcon} />
                </li>
                <li className={styles.formlist}>
                  outdoor Living{" "}
                  <IoIosArrowDown className={styles.formlistIcon} />
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.form_2x}>
            <div className={styles.formImage}>
              <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1736819380/userGeneratedImages/xsmgsj0i2ef37vmznbqn.png" alt="form Thumbnail" />
            </div>
            <div className={styles.form_2x1}>
              <h6>Theme : Modern</h6>
              <h2>Project Total</h2>
              <h3>Starting At</h3>

              <div>$295,987</div>
              <p>TO</p>
              <div>$304,987</div>
              <button>Claim This offer</button>
            </div>
          </div>
        </div>

        <div className={styles.formrightshadow}>
          <LayoutFillImg src="/assets/formrightcorner.png" alt="right shadow" />
        </div>
      </Container>
    </Section>
  );
};

export default HomePageEditor;
